import axios from "axios";
import { baseUrl } from "../config/apiUrlConfig";
var FormData = require('form-data');



async function  createOrderID(orderValue) 
{
    try{
        var data = new FormData();
        data.append('orderValue', orderValue);
        const response = await axios.post(
            baseUrl+"/order",
            data
        );

        return response.data;
    }
    catch(err){
        return err;
    }

}

async function verifyPayment(paymentID,orderID,signature)
{
    try{
        var data = new FormData();
        data.append('razorpay_payment_id', paymentID);
        data.append('razorpay_order_id', orderID);
        data.append('razorpay_signature', signature);
        
        const response = await axios.post(
            baseUrl+"/verify",
            data
        );

        return response.data;

    }
    catch(err){
        return err;
    }
}

export {createOrderID, verifyPayment}

