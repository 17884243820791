import React from "react";
import "./Loader.css"

const Loader = () => {
  console.log("it is called")
  return (
    <div className="loader-container">
      <div className="loader"></div>
      
    </div>
  );
};

export default Loader;
