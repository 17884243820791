import React from "react";
import AppRoutes from "./routes";

function Main() {
  return (
    <AppRoutes/>
  )
}

export default Main;
