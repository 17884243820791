import React from "react";
import styles from './privacyPolicy.module.css'

function PrivacyPolicyPage() {
  return (
    <div className={styles.privacyPolicyContainer}>
      <h2 className={styles.privacyPolicyHeading}>Privacy Policy</h2>
      <p className={styles.privacyPolicyContent}>
        RV Matrix Software Technologies Pvt Ltd built the Biblementor.in
        website/app as a Commercial app. This SERVICE is provided by RV Matrix
        Software Technologies Pvt Ltd and is intended for use as is.
      </p>
      <h2>Information Collection and Use</h2>
      <p>
        For a better experience, while using our Service, we may require you to
        provide us with certain personally identifiable information, including
        but not limited to Email, Phone Number, IP address, account number,
        credit card number, debit card number, Full-face photos and any
        comparable images, Telephone number, All elements of dates, Geographical
        location, Personal information. The information that we request will be
        retained by us and used as described in this privacy policy.
      </p>
      <h2>Cookies</h2>
      <p>
        Cookies are files with a small amount of data that are commonly used as
        anonymous unique identifiers. These are sent to your browser from the
        websites that you visit and are stored on your device's internal memory.
      </p>
      <h2>Service Providers</h2>
      <p>
        We may employ third-party companies and individuals due to the following
        reasons:
      </p>
      <p>
        · To facilitate our Service;
        <br />
        · To provide the Service on our behalf;
        <br />
        · To perform Service-related services; or
        <br />
        · To assist us in analyzing how our Service is used.
      </p>
      <h2>Security</h2>
      <p>
        We value your trust in providing us your Personal Information, thus we
        are striving to use commercially acceptable means of protecting it. But
        remember that no method of transmission over the internet, or method of
        electronic storage is 100% secure and reliable, and we cannot guarantee
        its absolute security.
      </p>
      <h2>Links to Other Sites</h2>
      <p>
        This Service may contain links to other sites. If you click on a
        third-party link, you will be directed to that site. Note that these
        external sites are not operated by us. Therefore, we strongly advise you
        to review the Privacy Policy of these websites. We have no control over
        and assume no responsibility for the content, privacy policies, or
        practices of any third-party sites or services.
      </p>
      <h2>Children’s Privacy</h2>
      <p>
        These Services do not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from children
        under 13. In the case we discover that a child under 13 has provided us
        with personal information, we immediately delete this from our servers.
        If you are a parent or guardian and you are aware that your child has
        provided us with personal information, please contact us so that we will
        be able to take necessary actions.
      </p>
      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. Thus, you are
        advised to review this page periodically for any changes. We will notify
        you of any changes by posting the new Privacy Policy on this page.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions or suggestions about our Privacy Policy, do
        not hesitate to contact us at S1/S2, B Block, Ameen Manors, 138,
        Nungambakkam High Road, Chennai 600034, Tamil Nadu, India. Email:
        contact@rvmatrix.in
      </p>
    </div>
  );
}

export default PrivacyPolicyPage;
