import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { loginUser } from "../../controller/userAuth.ts";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from "../Login/login.module.css";
// import { createOrderID,verifyPayment} from "../../controller/payments.ts";

function LoginPage() {
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    phonenumber: '',
    password: ''
  });

  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Show loader
    try {
      var isUserAuth = await loginUser(formValues);
      console.log(isUserAuth);
      if(isUserAuth.toString().includes("Subscription Expried") ) {
        const phoneNumber = isUserAuth.match(/\d+/)?.[0] || "";
        localStorage.setItem("userName", phoneNumber)
        navigate("/buynow");
      }
      else if(isUserAuth === "You are not a registered user")
      {
        navigate("/signUp");
      }
      else if (isUserAuth === "invalid credentials"){
        toast.error('Invalid credentials', { autoClose: 2000 });
      }
      else if (isUserAuth.toString().length === 10)
      {
        localStorage.setItem("userName", isUserAuth)
        navigate("/quranMentor");
      }
      else
      {
        toast.error('Invalid credentials', { autoClose: 2000 });
      }
    } catch (error) {
      console.log(error);
      toast.error('An error occurred', { autoClose: 2000 });
    } finally {
      setLoading(false); // Hide loader
    }
  };

  // For payment gateway - Loads the data which is required to render the payment gateway
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
     document.body.appendChild(script);
   });
};

// For payment gateway - Renders the requied view onto the screen
// async function displayRazorpay() {

//   // creating a new order -> pass the price as required
//   const result = await createOrderID(500);

//   if (!result) {
//       alert("Server error. Are you online?");
//       return;
//   }

//   // Getting the order details back from api
//   const amount = result.amount;
//   const currency = result.currency;
//   const order_id = result.id;

//   const options = {
//       key: "rzp_test_5rCocW1AKaNvvn",
//       amount: amount.toString(),
//       currency: currency,
//       name: "RV Matrix Software Solutions Pvt Ltd",
//       description: "Buy subscription to service",
//       order_id: order_id,
//       handler: async function (response) {
//         const isPaymentSucceful = verifyPayment(response.razorpay_payment_id,response.razorpay_order_id,response.razorpay_signature);
//         if(isPaymentSucceful === 'success')
//         {
//           //go to relevent page
//         }
//         else{
//           // throw failure
//         }
        
          
//       },
//       modal: {
//         ondismiss: function () {
//           toast.error('Payment failed or dismissed!');
//         },
//       },
//       theme: {
//           color: "#61dafb",
//       },
      
//   };

  
//   const paymentObject = new window.Razorpay(options);

//   // Handle payment failed event
//   paymentObject.on('payment.failed', function (response) {
//     toast.error(response.error.description);

//     // All data recieved on failure ---
//     // alert(response.error.code);
//     // alert(response.error.description);
//     // alert(response.error.source);
//     // alert(response.error.step);
//     // alert(response.error.reason);
//     // alert(response.error.metadata.order_id);
//     // alert(response.error.metadata.payment_id);
//   });
//   paymentObject.open();
// }



  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  });

  return (
    <>
      <ToastContainer />
      <div className={styles.loginContainer}>
        <div className={styles.loginBox}>
          <div className={styles.imageContainer}>

          </div>
          <div className={styles.formContainer}>
            <p className={styles.loginTitle}>Login Page</p>
            <form onSubmit={handleSubmit} className={styles.loginForm}>
              <label htmlFor="phonenumber">Phone Number:</label>
              <input
                type="tel"
                name="phonenumber"
                pattern="[0-9]{10}"
                value={formValues.phonenumber}
                onChange={handleInputChange}
                className={styles.inputField}
              />

              <label htmlFor="password">Password:</label>
              <input
                type="password"
                name="password"
                value={formValues.password}
                onChange={handleInputChange}
                className={styles.inputField}
              />

              {loading ? (
                <div className={styles.loader}></div>
              ) : (
                <button type="submit" className={styles.loginButton}>Login</button>
              )}
            </form>
            <div className={styles.signupLink}>
              <Link to="/signUp" className={styles.signupButton}>
                Sign Up
              </Link>
            </div>

            {
              // Test button for payment -> need to placed in the flow as required

              /* <div className={styles.signupLink}>
                  <button onClick={displayRazorpay}>Buy Now</button>
              </div> */
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
