import React from "react";
import styles from './privacyPolicy.module.css'

function CancellationPolicyPage() {
  return (
    <div className={styles.privacyPolicyContainer}>
      <h2 className={styles.privacyPolicyHeading}>Cancellation and Refund Policy</h2>
      <p className={styles.privacyPolicyContent}>
      You can cancel your www.biblementor.in services at any time by not paying for the following month, and you will continue to have access to the service through the end of your billing period. To the extent permitted by the applicable law, payments are non-refundable and we do not provide refunds or credits for any service periods or unused search content. If you cancel your membership, your account will automatically close at the end of your current billing period.
      </p>
      
    </div>
  );
}

export default CancellationPolicyPage;
