import React,{ useEffect } from "react";
import styles from "./payment.module.css";
import { createOrderID,verifyPayment} from "../../controller/payments.ts";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { buySubscription } from "../../controller/subscriptions.ts";



function ProductModal() {
  const product = {
    name: "Unlimited Questions",
    price: 75,
    description: "An amount of ₹75/week will be charged to search.",
  };

  var validityType = 0; //use setState when plans come in currently only weekly

  const navigate = useNavigate();


  const handleBuyNow = () => {
    // handle the logic here
    displayRazorpay(7500)
  };

  // For payment gateway - Loads the data which is required to render the payment gateway
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
     document.body.appendChild(script);
   });
  };

  //For payment gateway - Renders the requied view onto the screen
  async function displayRazorpay(productPrice) {

    // creating a new order -> pass the price as required
    const result = await createOrderID(productPrice);

    if (!result) {
        alert("Server error. Are you online?");
        return;
    }

    // Getting the order details back from api
    const amount = result.amount;
    const currency = result.currency;
    const order_id = result.id;

    const options = {
        key: "rzp_test_5rCocW1AKaNvvn",
        amount: amount.toString(),
        currency: currency,
        name: "RV Matrix Software Solutions Pvt Ltd",
        description: "Buy subscription to service",
        order_id: order_id,
        handler: async function (response) {
          const isPaymentSucceful = await verifyPayment(response.razorpay_payment_id,response.razorpay_order_id,response.razorpay_signature);
          if(isPaymentSucceful === 'success')
          {
            var userData = localStorage.getItem("userName");
            buySubscription(3,userData,validityType,response.razorpay_payment_id,response.razorpay_signature);

            //go to relevent page
            navigate("/quranMentor")
          }
          else{
            // throw failure
            navigate("/buynow")
          }  
        },
        modal: {
          ondismiss: function () {
            toast.error('Payment failed or dismissed!');
          },
        },
        theme: {
            color: "#61dafb",
        }, 
    };
    
    const paymentObject = new window.Razorpay(options);

    //Handle payment failed event
    paymentObject.on('payment.failed', function (response) {
      toast.error(response.error.description);
      // All data recieved on failure ---
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });
    paymentObject.open();
  }

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  });

  return (
    <div className={styles.container}>
      <ToastContainer/>
      <div className={styles.productModal}>
        <h2 className={styles.productName}>{product.name}</h2>
        <p className={styles.productDescription}>{product.description}</p>
        <h3 className={styles.productPrice}>Price: &#8377;{product.price}</h3>
        <button className={styles.buyNowButton} onClick={handleBuyNow}>
          Buy Now
        </button>
      </div>
    </div>
  );
}



export default ProductModal;
