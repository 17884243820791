import React from "react";
import styles from './privacyPolicy.module.css'

function ContactUS() {
  return (
    <div className={styles.privacyPolicyContainer}>
      <h2 className={styles.privacyPolicyHeading}>Contact US</h2>
      <p className={styles.privacyPolicyContent}>
      do not hesitate to contact us at S1/S2, B Block, Ameen Manors, 138, Nungambakkam High Road, Chennai 600034, Tamil Nadu, India. Email: contact@rvmatrix.in </p>
      
    </div>
  );
}

export default ContactUS;
