import axios from "axios";
import { baseUrl } from "../config/apiUrlConfig";
var FormData = require('form-data');


const buySubscription  = async (serviceID,userData,validityType,paymentId,transactionId) =>  {
    try {
        const formData = new FormData();
        formData.append("serviceID", serviceID);
        formData.append("phoneNumber", userData);
        formData.append("validity", validityType);
        formData.append("paymentId", paymentId);
        formData.append("transactionId", transactionId);
        console.log("Posting...")
        const response = await axios.post(
            baseUrl+"/paidSub",
            formData
        );
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}


export {buySubscription}
