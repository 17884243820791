import axios from "axios";
import { baseUrl } from "../config/apiUrlConfig";
var FormData = require('form-data');


const quranAPI = async (inputValue,userData) => {
    try {
        const formData = new FormData();
        formData.append("question", inputValue);
        formData.append("username",userData);
        console.log("Posting...")
        const response = await axios.post(
            baseUrl+"/quran",
            formData
        );
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export {quranAPI};