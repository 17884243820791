import React from "react";
import styles from './privacyPolicy.module.css'

function TermAndConditionPage() {
  return (
    <div className={styles.privacyPolicyContainer}>
      <h2 className={styles.privacyPolicyHeading}>Terms & Conditions</h2>
      <p className={styles.privacyPolicyContent}>
      By downloading or using the app or website, these terms will automatically apply to you – you should make sure therefore that you read them carefully before using the website/app. You’re not allowed to copy, or modify the website/app, any part of the website/app, or our trademarks in any way. You’re not allowed to attempt to extract the source code of the website/app, and you also shouldn’t try to translate the website/app into other languages, or make derivative versions. The website/app itself, and all the trade marks, copyright, database rights and other intellectual property rights related to it, still belong to RV Matrix Software Technologies Pvt Ltd.

RV Matrix Software Technologies Pvt Ltd is committed to ensuring that the website/app is as useful and efficient as possible. For that reason, we reserve the right to make changes to the website/app or to charge for its services, at any time and for any reason. We will never charge you for the website/app or its services without making it very clear to you exactly what you’re paying for.

The www.biblementor.in website/app stores and processes personal data that you have provided to us, in order to provide our Service. It’s your responsibility to keep your phone and access to the website/app secure. We therefore recommend that you do not jailbreak or root your phone, which is the process of removing software restrictions and limitations imposed by the official operating system of your device. It could make your phone vulnerable to malware/viruses/malicious programs, compromise your phone’s security features and it could mean that the www.biblementor.in website/app won’t work properly or at all.

The website/app does use third party services that declare their own Terms and Conditions.

Link to Terms and Conditions of third party service providers used by the app · Google Play Services · Google Analytics for Firebase · Firebase Crashlytics · One Signal

You should be aware that there are certain things that RV Matrix Software Technologies Pvt Ltd will not take responsibility for. Certain functions of the website/app will require the website/app to have an active internet connection. The connection can be Wi-Fi, or provided by your mobile network provider, but RV Matrix Software Technologies Pvt Ltd cannot take responsibility for the website/app not working at full functionality if you don’t have access to Wi-Fi, and you don’t have any of your data allowance left.

If you’re using the website/app outside of an area with Wi-Fi, you should remember that your terms of the agreement with your mobile network provider will still apply. As a result, you may be charged by your mobile provider for the cost of data for the duration of the connection while accessing the app, or other third party charges. In using the app, you’re accepting responsibility for any such charges, including

roaming data charges if you use the app outside of your home territory (i.e. region or country) without turning off data roaming. If you are not the bill payer for the device on which you’re using the website/app, please be aware that we assume that you have received permission from the bill payer for using the website/app.

Along the same lines, RV Matrix Software Technologies Pvt Ltd cannot always take responsibility for the way you use the website/app i.e. You need to make sure that your device stays charged – if it runs out of battery and you can’t turn it on to avail the Service, RV Matrix Software Technologies Pvt Ltd cannot accept responsibility.

With respect to RV Matrix Software Technologies Pvt Ltd’s responsibility for your use of the website/app, when you’re using the website/app, It is important to bear in mind that although we endeavour to ensure that it is updated and correct at all times, we do rely on third parties to provide information to us so that we can make it available to you. RV Matrix Software Technologies Pvt Ltd accepts no liability for any loss, direct or indirect, you experience as a result of relying wholly on this functionality of the website/app.

At some point, we may wish to update the website/app. The website/app is currently available on Android & iOS – the requirements for both systems(and for any additional systems we decide to extend the availability of the website/app to) may change, and you’ll need to download the updates if you want to keep using the website/app. RV Matrix Software Technologies Pvt Ltd does not promise that it will always update the website/app so that it is relevant to you and/or works with the Android & iOS version that you have installed on your device. However, you promise to always accept updates to the application when offered to you, We may also wish to stop providing the website/app, and may terminate use of it at any time without giving notice of termination to you. Unless we tell you otherwise, upon any termination, (a) the rights and licenses granted to you in these terms will end; (b) you must stop using the website/app, and (if needed) delete it from your device.
      </p>
      <h2>Ownership of the www.biblementor.in Services</h2>
      <p>
      RV Matrix Software Technologies Pvt Ltd reserves all right, title and interest in and to the www.biblementor.in website/application, including all related intellectual property rights. No rights are granted to user other than as expressly set forth herein, whether by implication, estoppel, or otherwise. All enhacements, modifications, and derivative works made to the www.biblementor.in website/application and all intellectual property rights therein, shall be owned by RV Matrix Software Technologies Pvt Ltd, whether or not such derivatives are made at user’s request or instruction, with the exception of any electronic data or information submitted, provided, or made available to www.biblementor.in by or on behalf of user to the RV Matrix Software Technologies Pvt Ltd (user data) contained in the derivatives which shall continue to be owned by user. User hereby irrevocably assigns to www.biblementor.in all right, title ad interest in and to the derivatives, including property rights therein that may inure to user or that user is deemed to obtain pursuant to this agreement.</p>
      <h2>Disclaimer, Limitations, Prohibitions</h2>
      <p>
      The user of the application is solely responsible for the actions when using ad relying on the www.biblementor.in services including the information pertaining to the user’s personal stored in the website/application. The user takes all the risks involved with his/her user information, including the data’s reliance on its quality, accuracy, authenticity or reliability. The user may expose himself/herself to liability, for example, if user related information contains material that is false, intentionally misleading, or defamatory; violates third party rights; or contains material that is unlawful or advocates the violation of any law or regulation. Therefore, the user undertake to comply with all the security measures and obligations applicable, excluding www.biblementor.in of any liability regarding such datawww.biblementor.in reserves all right to terminate the account of the user if it detects or assesses anything illicit, or contrary to morality or public order, or that is detrimental to the brand of www.biblementor.in and/or the content of the application or its produce or services of third parties, as well as offensive content that could incite, promote or advertise illegal activities or defamatory, pornographic content, violent or discriminatory attitudes or promote discrimination for reasons of race or ethnicity, gender, affiliation, creed or sexual orientation.

The user is solely responsible for contributing for testimonies and forums. www.biblementor.in doesn’t exercise any control over content provided by users.
      </p>
        
    </div>
  );
}

export default TermAndConditionPage;
