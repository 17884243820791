import { BrowserRouter, Routes, Route } from "react-router-dom";
import QuranPage from "../pages/Quran/quran.js";
import LoginPage from "../pages/Login/login.js";
import SignupPage from "../pages/Signup/signup.js";
import CancellationPolicyPage from "../pages/modals/cancellation.js";
import PrivacyPolicyPage from "../pages/modals/privacyModal.js";
import TermAndConditionPage from "../pages/modals/termsAndConditions.js";
import ContactUS from "../pages/modals/contactUs.js";
import ProductModal from "../pages/product/payment.js";

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<LandingPage/>}></Route> */}
        <Route path="/" element={<LoginPage/>}></Route>
        <Route path="/signUp" element={<SignupPage />}></Route>
        <Route path="/quranMentor" element={<QuranPage/>}></Route>
        <Route path="/privacypolicy" element={<PrivacyPolicyPage/>} /> 
        <Route path="/cancellationpolicy" element={<CancellationPolicyPage/>}/>
        <Route path = "/termsandcondition" element={<TermAndConditionPage/>}/>
        <Route path="/contactUs" element={<ContactUS/>}/>
        <Route path="/buynow" element={<ProductModal/>}/>
      </Routes>
    </BrowserRouter>
  );
}
